// in the future we could rather offer a function to get the translations for a specific language

export const TRANSLATIONS = {
    menu: {
        usage: 'Funktionen',
        waitlist: 'Warteliste'
    },
    landing: {
        hero: {
            title: 'Online-Terminbuchung für Psychologen und Therapeuten',
            subtitle: 'Mehr Zeit für die wichtigen Dinge.',
            introduction1:
                'Vereinfachen Sie Terminplanung und Datenverwaltung. Automatisieren Sie Erinnerungen und Kommunikation mit Klienten – alles auf einer intuitiven, datenschutzkonformen Plattform.',
            '30daysFreePart1': '30 Tage lang',
            '30daysFreePart2': 'Risiko- und kostenfrei testen'
        },
        baseLineSummary: {
            title: 'Unsere Grundsätze',
            description:
                'Tebuto ist ein deutsches Unternehmen mit Sitz in Karlsruhe. Wir sind ein Team von Software-Experten, das sich auf die Entwicklung einer Terminbuchungslösung für Psycholog:innen und Therapeut:innen spezialisiert hat. Unser Ziel ist es, Ihnen die Arbeit zu erleichtern und Ihren Klienten und Kunden eine einfache und ansprechende Möglichkeit der Terminbuchung zu bieten.',
            dataPrivacy: {
                title: 'Höchster Datenschutz',
                description:
                    'Klienten- und Kundendaten sind sehr sensitiv und sollten unter keinen Umständen in die falschen Hände geraten. Natürlich erfüllen wir die Anforderungen der Datenschutzgrundverordnung (DSGVO). Wir gehen aber noch einen Schritt weiter und sichern Ihre Daten an vielen Stellen noch ein wenig umfassender.'
            },
            externalFinancing: {
                title: 'Keine externen Geldgeber',
                description:
                    'Tebuto wird durch die Gründer selbst finanziert. Bei uns gibt es keine externen Geldgeber oder Investoren, die Einfluss auf unsere Entscheidungen nehmen. Allein Ihre Zufriedenheit und Zeitersparnis leiten unsere Arbeit.'
            },
            communityDrivenDevelopment: {
                title: 'Nutzergetriebene Entwicklung',
                description:
                    'Wir sind der Überzeugung, dass gute Software durch regelmäßiges Feedback und sinnvolle Anpassungen entsteht. Deshalb entwickeln wir Tebuto in enger Zusammenarbeit mit Ihnen. Regelmäßig können Sie über neue Funktionalitäten und Änderungen abstimmen, die wir bei entsprechendem Bedarf umsetzen.'
            }
        },
        waitlist: {
            CTAPart1: 'Sie möchten Ihre Terminvergabe deutlich vereinfachen?',
            CTAPart2: 'Wir informieren Sie über alle Neuigkeiten zu Tebuto.',
            processingAgreement: 'Ich stimme der Verarbeitung meiner E-Mail-Adresse zum Zweck der Registrierung auf der Warteliste und der Kontaktaufnahme durch Tebuto zu.',
            register: 'Eintragen',
            emailPlaceholder: 'Ihre E-Mail Adresse',
            successMessage: 'Danke für Ihr Interesse! Wir werden Sie informieren, sobald es Neuigkeiten gibt.',
            errors: {
                agreementMissing: 'Bitte stimmen Sie der Verarbeitung Ihrer E-Mail-Adresse zu.',
                emailMissing: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
                emailInvalid: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
                generic: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
            }
        },
        features: {
            appointments: {
                subTitle: 'Sparen Sie Zeit und Geld mit einer flexiblen Lösung bei voller Kontrolle.',
                title: 'Termine erstellen',
                description:
                    'Ermöglichen Sie Ihren Klienten maximale Flexibilität bei der Terminwahl und und behalten Sie dabei so viel Kontrolle wie Sie wünschen. Sie können frei gestalten: Termine zu regelmäßigen Zeiten anbieten, individuell auf Terminwünsche von Klienten eingehen oder beides kombinieren. Tebuto unterstützt Sie in Ihrer Termingestaltung durch intelligente automatisierte Prozesse.',
                features: {
                    ruleBasedAppointments: {
                        name: 'Regelbasierte Termine',
                        description: 'Ihre Termine finden immer zu bestimmten Zeiten statt? Mit Tebuto generieren Sie ganz automatisch Termine nach Ihren Regeln.'
                    },
                    integrateCalendars: {
                        name: 'Kalender integrieren',
                        description:
                            'Private und berufliche Termine kombinieren. Integrieren Sie Ihren privaten Google, Apple oder Outlook-Kalender und lassen Sie Tebuto automatisch Ihre privaten Termine berücksichtigen.'
                    },
                    clientFlexibility: {
                        name: 'Individuelle Terminvergabe',
                        description:
                            'Sie möchten flexibel auf die Terminwünsche Ihrer Klienten eingehen? Mit der Terminverwaltung von Tebuto können Sie diese Flexibilität auf effiziente Weise bieten und jederzeit den Überblick behalten.'
                    }
                }
            },
            shareAppointments: {
                subTitle: 'Sichere und einfache Abstimmung von Terminen mit Klienten',
                title: 'Sicherer Zugang für Klienten, ganz ohne Passwörter',
                description:
                    'Terminabsprachen können kompliziert und aufwändig sein. Das muss nicht sein. Ihre Klienten sehen in Tebuto alle aktuell verfügbaren Termine und wählen nach eigenen Wünschen Termine aus. Sie bestätigen den Termin anschließend nur. Oder Sie akzeptieren gebuchte Termine automatisch. Ganz wie Sie möchten. Sie sparen Zeit und Ihre Klienten stimmen Therapie und Privatleben optimal aufeinander ab.',
                features: {
                    personalClientLinks: {
                        name: 'Passwortloser Zugang für Klienten',
                        description: 'Der technische Betreuungsaufwand wird hierdurch minimiert und auch weniger technikbegeisterte Klienten kommen mit dieser Lösung zurecht.'
                    },
                    clientDataProtection: {
                        name: 'Schutz Ihrer Klienten und deren Daten',
                        description:
                            'Die Daten Ihrer Klienten sind sensibel und sollten unter keinen Umständen in die falschen Hände geraten. Deshalb schützen wir die Daten von Ihnen und Ihrer Klienten mit modernsten Technologien und höchsten Sicherheitsstandards.'
                    },
                    improvedUserExperience: {
                        name: 'Verbesserte Nutzererfahrung',
                        description:
                            'Ihre Klienten können ganz bequem und in Ruhe den Termin auswählen, der aktuell am besten passt. Dabei setzen wir auf eine minimalistische und intuitive Oberfläche, die sich auf das Wesentliche konzentriert.'
                    }
                }
            },
            communication: {
                subTitle: 'Terminerinnerungen, Ausfallhonorare und Infomaterial',
                title: 'Kommunikation automatisieren',
                description:
                    'Viele Ihrer Alltagsprozesse sind trotz unterschiedlicher Klienten ähnlich oder sogar gleich. Tebuto hilft Ihnen, diese Prozesse zu automatisieren und spart so Zeit und verhindert Fehler.',
                features: {
                    flexibleEventNotifications: {
                        name: 'Flexible, anlassbasierte Benachrichtigungen',
                        description:
                            'Der Kontakt zwischen Ihnen und Ihren Klienten erfolgt in aller Regel bei bestimmten Ereignissen oder Zeitpunkten. Diese nutzen wir als Startpunkt für verschiedene Benachrichtigungen für Terminbestätigungen, Terminerinnerungen, Infomaterial oder auch die Abrechnung von Ausfallhonoraren.'
                    },
                    automationWithoutLoss: {
                        name: 'Automatisierung mit persönlicher Kommunikation',
                        description:
                            'Niemand mag generische Ansprachen. Deshalb können Sie alle Benachrichtigungen personalisieren und an Ihre Bedürfnisse sowie die Ihrer Klienten anpassen.'
                    },
                    avoidMistakes: {
                        name: 'Fehler vermeiden',
                        description:
                            'Tebuto unterstützt Sie dabei, Fehler in der Kommunikation zu vermeiden. Durch einfach zu bedienende Platzhalter stellen wir sicher, dass in der persönlichen Ansprache keine Fehler passieren und alle Daten korrekt sind.'
                    }
                }
            },
            directory: {
                subTitle: 'Sichtbarkeit erhöhen',
                title: 'Optionaler Verzeichniseintrag',
                description:
                    'Wenn Sie möchten, können Sie Ihr Profil in unserem Verzeichnis von Psycholog:innen und Therapeut:innen veröffentlichen und so mehr Menschen erreichen.',
                features: {
                    seoOptimizedStructuredData: {
                        name: 'SEO & strukturierte Daten',
                        description:
                            'Wir zeichnen die Daten Ihres Unternehmens mit strukturierten Daten aus, damit Suchmaschinen wie beispielsweise Google diese besser verstehen und Sie in den Suchergebnissen besser gefunden werden.'
                    },
                    highTrustworthiness: {
                        name: 'Hohe Vertrauenswürdigkeit',
                        description: 'Damit nur seriöse und professionelle Angebote in unserem Verzeichnis gelistet werden, prüfen wir alle Anbieter und deren Inhalte manuell.'
                    },
                    increasedVisibility: {
                        name: 'Reichweitensteigerung',
                        description: 'Profitieren Sie von der Sichtbarkeit von Tebuto und sichern Sie sich einen hoch-relevanten do-follow Backlink für Ihre Webseite.'
                    }
                }
            }
        },
        stats: {
            title: 'Zeit und Geld sparen',
            description:
                'Termine und Klienten im Blick zu behalten ist wesentlicher Bestandteil der täglichen Arbeit von Psycholog:innen und Therapeut:innen. Wir helfen Ihnen dabei, viele Prozesse zu automatisieren, damit Sie flexibel sind und Ihre Zeit effektiver nutzen können.',
            disclaimer:
                '* In unserem Rechenbeispiel reduzieren wir die Zeit, die Sie täglich mit der Verwaltung Ihrer Termine und Klienten verbringen, von 60 auf circa 10 Minuten. Das entspricht einer Reduktion von 10.000 Minuten im Jahr bei 200 Arbeitstagen. Bei einem durchschnittlichen Stundensatz von 80 € und einer Terminlänge von 50 Minuten, entspricht dies bereits einem gesparten Aufwand von 16.000 € im Jahr. Zu den berücksichtigten Aufgaben gehören beispielsweise Terminabsprachen, Terminerinnerungen, der Versand von Infomaterial und die Abrechnung von Ausfallhonoraren.',
            values: {
                daily: {
                    value: '50 Minuten',
                    unit: 'täglich'
                },
                yearlyTime: {
                    value: '10.000 Minuten',
                    unit: 'jährlich'
                },
                yearlyMoney: {
                    value: '16.000 €',
                    unit: 'jährlich'
                }
            }
        },
        testimonials: {
            sarah: {
                content:
                    'Bevor ich Tebuto genutzt habe, hat mich das Termin-management täglich bis zu 60min gekostet. Jetzt benötige ich dafür wöchentlich 15min und kann die frei gewordene Zeit für mich und meine Klient:innen nutzen.',
                name: 'Sarah Scholl',
                company: 'Richtungswechsler in Karlsruhe'
            }
        },
        pricing: {
            title: 'Ein Preis. Keine Tricks.',
            description:
                'Wir möchten Ihre Arbeit einfacher machen und unsere Arbeit ethisch gestalten. Deshalb gibt es bei uns keine versteckten Kosten oder Aufpreise für neue Funktionen.',
            modelName: 'Monatliches Abonnement',
            modelDescriptionPart1: 'Testen Sie Tebuto',
            modelDescriptionPart2: '30 Tage kostenlos',
            modelDescriptionPart3:
                'ohne Angabe von Zahlungsmitteln. Anschließend erfolgt die Bezahlung bequem monatlich und Sie können jederzeit kündigen. Neue Funktionen sind automatisch für alle unsere Nutzer und ohne Aufpreis oder neue Tarife verfügbar.',
            whatsIn: 'Leistungsumfang',
            CTA: 'in die Warteliste eintragen',
            price: '99€',
            priceOccurence: 'Pro Monat',
            priceUnit: 'Euro',
            coniditions: 'Rechnungen werden monatlich online bereitgestellt und per E-Mail versendet.',
            features: {
                unlimitedAppointments: 'Unbegrenzte Anzahl an Terminen',
                unlimitedClients: 'Unbegrenzte Anzahl an Klienten',
                allFunctions: 'Zugriff auf alle Integrationen und Funktionen',
                worldwideResponsive: 'Weltweit und auf jedem Gerät verfügbar'
            }
        },
        FAQ: {
            title: 'Häufig gestellte Fragen',
            items: [
                {
                    question: 'Gibt es eine kostenlose Testversion?',
                    answer: 'Ja! Sie können Tebuto 30 Tage kostenlos testen und müssen dafür keine Zahlungsmittel angeben. Anschließend erfolgt die Bezahlung bequem monatlich oder jährlich und Sie können jederzeit kündigen.'
                },
                {
                    question: 'Welche Bezahlmöglichkeiten gibt es?',
                    answer: 'Der Rechnungsbetrag kann automatisch über Kreditkarte, PayPal oder SEPA-Lastschrift eingezogen werden. Alternativ können Sie die Rechnung per Überweisung begleichen.'
                },
                {
                    question: 'Wie kann ich das Abonnement kündigen?',
                    answer: 'Sie können Ihr Abonnement jederzeit kündigen. Das können Sie ganz bequem, leicht und direkt über Ihren Account tun.'
                },
                {
                    question: 'Wie werde ich unterstützt, wenn ich Fragen oder Probleme habe?',
                    answer: 'Wir sind persönlich für Sie da! Sie können uns jederzeit per E-Mail erreichen. Wir antworten in der Regel innerhalb von 24 Stunden. Sollten Sie ein dringendes Problem haben, so können Sie uns auch montags bis freitags zwischen 08:00 und 18:00 Uhr telefonisch erreichen.'
                },
                {
                    question: 'Kann ich Tebuto auch nutzen, wenn ich kein/e Psycholog:in/Therapeut:in bin?',
                    answer: 'Tebuto ist grundsätzlich für alle Berufsgruppen geeignet, die Termine mit Klienten oder Kunden vereinbaren. Wir haben uns jedoch auf Psycholog:innen und Therapeut:innen spezialisiert. Einige Funktionen sind daher speziell auf diese Berufsgruppe zugeschnitten.'
                },
                {
                    question: 'Gibt es eine jährliche Zahlungsoption?',
                    answer: 'Wenn Sie sich für eine jährliche Zahlung entscheiden, erhalten Sie 2 Monate gratis. Sie bezahlen also nur 10 Monate pro Jahr (990€).'
                }
            ]
        }
    }
}
